.general{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 35vh;
}

.container {
    display: flex;
    margin: 20px 10px;
}