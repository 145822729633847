.general {
    min-height: 50vh;
}


.furnitureContainer {
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}