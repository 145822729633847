.general {
    box-shadow: 0px 0px 24px -9px rgba(66, 68, 90, 1);
    margin: 10px;
    margin-top: 30px;
}

.image {
    max-height: 24vh;
    padding: 10px;
}

.linkContainer {
    padding: 0 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
}

.deleteButton {
    box-shadow: none;
    background-color: white;
    border: 1px solid red;
    padding: 4px;
    border-radius: 3px;
    margin-left: 10px;
    color: red;
    transition: all 0.5s;
}

.deleteButton:hover {
    background-color: lightcoral;
    cursor: pointer;
}

.imageContainer {

    display: flex;
    justify-content: center;
    width: 100%;
}