@import '../../../../../variables.scss';

.general {
    font-weight: lighter;
}

.categories {
    font-weight: lighter;
    margin: 20px;
}

.categoriesUl {
    padding-left: 0px;
    margin-left: 20px;
    border-top: 1px solid rgba(128, 128, 128, 0.189);
    list-style: none;
}

.nameContainer {
    display: flex;
    align-items: center;
}

.clearButton {
    text-align: center;
    font-weight: lighter;
    cursor: pointer;
    border: 1px solid lightgray;
    padding: 10px 13px;
    font-size: medium;
    border-radius: 8px;
    transition: all 0.3s;
    user-select: none;
}

.clearButton:hover {
    background-color: white;
    color: $activeLink;
    border-color: $activeLink;
}

.hiddenClearButton {
    text-align: center;
    font-weight: lighter;
    cursor: pointer;
    border: 1px solid lightgray;
    padding: 10px 13px;
    font-size: medium;
    border-radius: 8px;
    transition: all 0.1s;
    opacity: 0;
    cursor: default;
    user-select: none;
}

@media screen and (max-width: 940px) {

    .general {
        display: none;
    }
}