.general{
    border-top: 1px solid lightgrey;
}
.general:hover{
    background-color: rgb(235, 235, 235);
    box-shadow: 0px 0px 5px 1px rgba(66, 68, 90, 1);
}

.listContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    overflow: auto;
}

.container {
    width: 90%;
    display: flex;
    align-items: center;
    margin: 1px 0;
}


.editFormContainer {
    display: flex;
    width: 90vw;
    margin-left: 5vw;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2px;
    padding-bottom: 20px;
    padding-top: 5px;
    border-top: 1px solid lightgray;
}

.image {
    margin-left: 15px;
    max-height: 50px;
    object-fit: contain;
    margin-right: 50px;
    max-width: 170px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 70px;
    border-right: 1px solid lightgray;
}

.link{
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 10px;
    border-right: 1px solid lightgray;
}

.name{
    display: flex;
    align-items: center;
    margin-left: 20px;
    min-width: 300px;
    height: 100%;
    border-right: 1px solid lightgray;
}

.changeImage,
.changeName,
.changeLink {
    margin-left: 20px;
    min-width: 300px;
}

.link {
    min-width: 200px;
}

.editButton,
.deleteButton {
    margin: 20px
}

.input {
    margin-left: 10px;
}

.changeName,
.changeLink {
    background-color: #9a0000;
    border-radius: 4px;
    color: white;
    padding: 8px 12px;
}

.saveButton{
    margin-left: 20px;
    border-style: none;
    background-color: #9a0000;
    border-radius: 4px;
    color: white;
    padding: 12px 12px;
}

.saveButton:hover{
    background-color: #5a0000;
    cursor: pointer;
}

