@import '../../../../../../variables.scss';

.buttonsContainer {
    display: flex;
    justify-content: space-around;
    background-color: $lightGray;
    z-index: 500;
    width: 80vw;
    max-width: calc(100% - 20vw);
    padding: 0 10vw;
}

.popUpNavigation {
    top: 0;
    position: fixed;
    background-color: white;
    border-bottom: 1px solid $lightGray;
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    z-index: 500;
    width: 80vw;
    max-width: calc(100% - 20vw);
    padding: 0 10vw;
}

.logo {
    width: 100px;
    padding-left: 15vw;
}

.buttonPart {
    color: $black;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    padding: 15px;
    font-weight: lighter;
    user-select: none;
}

.buttonPart:hover {
    color: $activeLink;
    background-color: rgb(226, 226, 226);
}

.firstLane {
    display: flex;
    width: 100vw;
    max-width: 100%;
    transition: all 0.5s;
}

@media screen and (max-width: 940px) {
    .webContainer {
        display: none;
    }
}