.general {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    user-select: none;
}

.image {
    max-width: 85vw;
    height: 45vh;
    max-height: 50%;
    border-radius: 10px;
	object-fit: contain;
}

.rightArrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: black;
    z-index: 90;
    cursor: pointer;
    user-select: none;
    border-radius: 40%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
    height: 8%;
}

.leftArrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: black;
    z-index: 90;
    cursor: pointer;
    user-select: none;
    border-radius: 40%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
    height: 8%;

}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}
.slideActive{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.loadingContainer{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 870px){
    .image{
        max-width: none;
        width: 90vw;
    }
}
