@import '../../variables.scss';

.general {
    display: flex;
    width: 100vh;
    max-width: 100%;
    align-items: center;
    max-height: 100%;
    transition: all 0.5s;
}

.select1,
.select2 {
    cursor: pointer;
}

.select1 {
    min-width: calc(50vw - 10px);
    height: 100vh;
    border-right: solid 10px $ufoYellow;
    display: flex;
    justify-content: center;
    transition: all 0.5s;
    user-select: none;

}

.cityName {
    padding-top: 100px;
    text-align: center;
    font-size: 40px;
    font-weight: lighter;
}

.select2 {
    min-width: calc(50vw - 10px);
    max-width: 100%;
    height: 100vh;
    border-left: solid 10px $ufoBlue;
    transition: all 0.5s;
    user-select: none;
}

.logoContainer {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    bottom: 20px;
}

.logo {
    height: 50vh;
    max-height: 20vh;
}

.descriptionContainer {
    display: flex;
    justify-content: center;
}

.description {
    padding: 20px 40px;
    border-radius: 100px;
    font-size: 20px;
    border: 1px solid $ufoBlue;
    background-color: white;
    user-select: none;
}

.select1:hover {
    box-shadow: inset 0px 0px 100px 17px rgba(0, 0, 0, 1);
}

.select2:hover {
    box-shadow: inset 0px 0px 100px 17px rgba(0, 0, 0, 1);
}

.mobileGeneral {
    display: none;
    font-size: 30px;
    font-weight: lighter;
}

.mobileSelect1 {
    height: calc(35vh - 31px);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    border-bottom: 1px solid $ufoYellow;
    text-align: center;
    user-select: none;
}

.mobileSelect2 {
    height: calc(35vh - 31px);
    border-top: 1px solid $ufoYellow;
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    user-select: none;
}

.mobileLogoContainer {
    display: flex;
    justify-content: center;
    height: calc(30vh - 2px);
    border-bottom: 1px solid $ufoBlue;
    border-top: 1px solid $ufoBlue;
    flex-wrap: wrap;
    width: 100vw;
}

.mobileDescription{
    text-align: center;
    min-width: 90vw;
    width: 100%;
    font-weight: lighter;
    font-size: 20px;
}

.mobileLogo{
    max-height: 50%;
}

@media screen and (max-width: 860px) {
    .general {
        display: none;
    }

    .mobileGeneral {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-height: 340px) {
    .mobileDescription{
        font-size: 18px;
    }
    
    .mobileLogo{
        height: 45%;
    }
}

@media screen and (max-height: 460px) {
    .description{
        font-size: 15px;
        padding: 5px 15px;
    }

    .cityName{
        font-size: 25px;
    }
}

@media screen and (max-height: 270px) {
    .mobileLogo{
        display: none;
    }
}

@media screen and (max-width: 260px) {
    .mobileLogo{
        height: 35%;
    }
}