.producers{
    display: flex;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    padding: 1%;
}

.producers:hover{
    background-color: lightblue;
}

.buttonContainer{
    display: flex;
    justify-content: center;
}

.furnituresDataContainer{
    border-bottom: 3px solid grey;
}

.addButton{
    margin: 5px;
    border: 1px solid green;
    padding: 20px;
    font-size: 15px;
    background-color: white;
    border-radius: 5px;
    color: green;
    transition: all 0.5s;
}

.addButton:hover{
    cursor: pointer;
    background-color: greenyellow;
}

.categoryContainer {
    padding: 0 20px;
    min-width: 330px;
}

.descriptionInput{
    display: flex;
    height: 70%;
    width: 50vw;
    min-height: 30vh;
    max-width: 80%;
    flex-wrap: wrap;
}

.smallCategory {
    margin-left: 20px;
}

.formContainer{
    background-color: lightyellow;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.input{
    margin-left: 15px;
}

.divPremisesCheckbox{
    background-color: rgba(255, 166, 0, 0.321);
    margin: 1px;
    border: 1px solid orange;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.downloadButton {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    color: #007700;
    border: 1px solid #007700;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s, transform 0.3s;
    text-decoration: none;
    margin-bottom: 40px;
}

.downloadButton:hover {
    background-color: #007700;
    color: #fff;
    border-color: #fff;
    transform: scale(1.25); 
}