@import '../../../variables.scss';

.container{
    width: 290px;
    border-radius: 5px;
    min-width: 17vw;
    max-width: 90vw;
    margin: 10px 5px;
    box-shadow: 0px 0px 15px -13px rgba(66, 68, 90, 1);
    transition: box-shadow 0.7s;
    padding-left: 2px;
    padding-right: 2px;
    cursor: pointer;
    padding-bottom: 5px;
    user-select: none;
}

.container:hover{
    transition: box-shadow 0.2s;
    box-shadow: $shadow2;
}

.image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    padding-top: 5px;
    image-rendering: optimizeQuality;
}

.name{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 10px;
    margin-bottom: 0.5rem;
    font-weight: lighter;
    color: black;
}

.dimension {
    color: gray;
}

.dimensions{
    font-size: 0.8rem;
    margin: 0 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: lighter;
}

.price{
    margin: 10px 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    font-weight: lighter;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    color: black;
    vertical-align: middle;
}

.dimensionValue{
    margin-left: 10px;
    color: black;
    font-weight: lighter;
}

.crossed{
    margin-left: 10px;
    text-decoration: line-through 1px;
}

@media screen and (max-width: 940px){

    .container:hover{
        box-shadow: none;
    }

    .container{
        min-width: none;
        max-width: none;
    }

    .image{
        max-height: 45vh;
    }

    .name{
        font-size: 22px;
    }
}