@import '../../variables.scss';

.general {
    display: flex;
    justify-content: center;
}

.categoriesContainer {
    width: 20%;
}

.furnitureContainer {
    width: 80vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.search {
    margin: 25px;
    display: flex;
    justify-content: center;
    margin: 25px 5vw;
    border: 1px solid lightgray;
    border-radius: 5px;
    transition: outline 1s ease;
}

.searchIcon {
    padding: 10px 0px 10px 10px;
}

.searchInput {
    padding-left: 10px;
    font-size: large;
    border: none;
    background-color: none;
    height: 2em;
    width: 100%;
    outline: none;
    font-weight: lighter;
}


.searchButton {
    min-width: 100px;
    font-size: medium;
    border: none;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
    font-weight: lighter;
}

.filterButton {
    background-color: white;
    min-width: 100px;
    border: 1px solid lightgray;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
    font-weight: lighter;
    padding: 10px;
}

.searchButton:hover {
    color: $activeLink;
    border: 1px solid $activeLink;
    background-color: white;
}

.filterButton:hover {
    color: $activeLink;
    border: 1px solid $activeLink;
}

.searchButton:active {
    background-color: rgba(0, 0, 0, 0.225);
    color: $activeLink;
}

.filterButton:active {
    background-color: rgba(0, 0, 0, 0.225);
    color: $activeLink;
}

.filters {
    padding: 30px 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select {
    min-width: 155px;
    border: none;
    border-radius: 4px;
    flex-wrap: wrap;
    border: 1px dotted lightgray;
    padding: 10px;
    margin: 0 10px;
}

.sortOption {
    list-style: none;
}

@media screen and (max-width: 940px) {

    .select{
        min-width: none;
        width: 100%;
        background-color: white;
        margin: 5px 0;
        text-align: center;
    }

    .furnitureContainer{
        width: 100%;
    }

    .search{
        display: none;
    }

    .searchButton{
        min-width: none;
    }

    .filters{
        display: none;
    }

    .filterButton{
        width: 100vw;
        max-width: 100%;
        background-color: white;
        color: gray;
    }
    .searchIcon{
        margin-left: 7px;
    }

    .categoriesContainer{
        display: none;
    }
}