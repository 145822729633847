@import '../../../../../../../../variables.scss';

.selectShowroomContainer {
    display: flex;
    align-items: center;
    margin-left: 100px;
    font-size: xx-large;
    user-select: none;
}

.selectedShowroomContainer {
    display: flex;
}

.selectedShowroom {
    color: black;
    font-weight: lighter;
}

.unselectedShowroom {
    padding-left: 20px;
    color: rgb(198, 198, 198);
    font-weight: lighter;
    font-weight: 100;
    cursor: pointer;
    transition: all 0.5s;
}

.unselectedShowroom:hover {
    color: $activeLink;
}

.adminDiv {
    margin: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.loggedIn {
    margin-left: 25px;
}

.showroomChip {
    margin-left: 5px;
}
