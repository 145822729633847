@import '../../../variables.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: transform 0.2s;
}

.image {
    max-width: 95vw;
    min-width: 30vw;
    width: 450px;
    margin: 5px;
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 5px;
    cursor: default;
    transition: border-radius 0.8s;
    user-select: none;
}

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: large;
    background-color: rgba(255, 255, 255, 0.833);
    padding: 20px;
    border-radius: 3px;
    color: black;
    transition: color 0.5s;
    user-select: none;

}

.text:hover {
    transition: color 0.2s;
    color: $activeLink;
}

.image:hover {
    border-radius: 0px;
}

.container:hover {
    transform: scale(1.01);
}

@media screen and (max-width: 870px) {

    .image {
        padding: 0;
        max-height: 70vh;
        margin: 5px 0;
        border-radius: 0;
        max-width: 100vw;
    }

    .container:hover {
        transform: none;
    }
}