$lightGray: rgb(236, 236, 236);
$activeLink: rgba(0, 21, 255, 0.658);
$black: rgba(0, 0, 0, 0.81);
$ufoBlue: #283890;
$ufoYellow: #FFF33D;
$ufoYellowHover: rgb(199, 197, 44);

$shadow1: 0px 0px 10px 0px rgb(16 7 227);
$shadow2: 0px 0px 5px -2px rgb(7, 77, 227);
$shadow2: 0px 0px 5px -2px rgb(7, 77, 227);



$uf3oYellow: #a5a5a5;