@import '../../variables.scss';

.general{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 100%;
    font-weight: lighter;
    user-select: none;
}

.generalContainer{
    width: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer{
    box-shadow: 0px 0px 20px -8px rgba(66, 68, 90, 1);
    width: 100%;
    padding: 5%;
    padding-bottom: 90px;
    height: 100%;
    max-height: 90vh;
}

.captionLogin{
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
    margin-top: 20px;
    user-select: none;
}

.cityNameContainer{
    text-align: center;
    user-select: none;
}

.buttonContainer{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.logo{
    height: 200px;
    cursor: pointer;
}

.imageContainer{
    display: flex;
    justify-content: center;
}

.passwordInputContainer{
    display: flex;
    justify-content: center;
    width: 100%;
}

.passwordInput{
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 100%;
    height: 30px;
}

.selectedShowroom{
    font-size: 30px;
}

.unselectedShowroom{
    font-size: 20px;
    color: lightgray;
    cursor: pointer;
}

.loginButton{
    width: 100%;
    height: 50px;
    border: none;
    margin-top: 20px;
    background-color: $ufoBlue;
    color: white;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.5s;
}

.loginButton:hover{
    background-color: #1d2868;
}


@media screen and (max-height: 540px) {

    .logo{
        padding-top: 20px;
        height: 100px;
    }

    .captionLogin{
        margin-bottom: 20px;
        
    }
}
